var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('validation-observer',{ref:"productForm"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.SaveUpdateChanges.apply(null, arguments)}}},[_c('b-row',[_c('b-col',{staticClass:"mb-1",attrs:{"md":"6","xl":"6"}},[_c('b-form-group',{attrs:{"label":"Cedula *"}},[_c('validation-provider',{attrs:{"name":"cedula","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"number","maxlength":"15"},on:{"keypress":_vm.filterNumber},model:{value:(_vm.formData.id_card),callback:function ($$v) {_vm.$set(_vm.formData, "id_card", $$v)},expression:"formData.id_card"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Nombre *"}},[_c('validation-provider',{attrs:{"name":"nombre","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"maxlength":"15"},on:{"keypress":_vm.filterString},model:{value:(_vm.formData.name),callback:function ($$v) {_vm.$set(_vm.formData, "name", $$v)},expression:"formData.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Apellido *"}},[_c('validation-provider',{attrs:{"name":"apellido","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"maxlength":"15"},on:{"keypress":_vm.filterString},model:{value:(_vm.formData.last_name),callback:function ($$v) {_vm.$set(_vm.formData, "last_name", $$v)},expression:"formData.last_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Correo *"}},[_c('validation-provider',{attrs:{"name":"correo","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{model:{value:(_vm.formData.email),callback:function ($$v) {_vm.$set(_vm.formData, "email", $$v)},expression:"formData.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Contraseña *"}},[_c('validation-provider',{attrs:{"name":"contraseña","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"password"},model:{value:(_vm.formData.password),callback:function ($$v) {_vm.$set(_vm.formData, "password", $$v)},expression:"formData.password"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),(_vm.typeUser == 'student' || _vm.typeUser == 'teacher')?_c('b-form-group',{attrs:{"label":"Grado *"}},[_c('validation-provider',{attrs:{"name":"grado","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{model:{value:(_vm.formData.degree),callback:function ($$v) {_vm.$set(_vm.formData, "degree", $$v)},expression:"formData.degree"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2151615603)})],1):_vm._e(),(_vm.typeUser == 'student' || _vm.typeUser == 'teacher')?_c('b-form-group',{attrs:{"label":"Sección *"}},[_c('validation-provider',{attrs:{"name":"sección","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{model:{value:(_vm.formData.section),callback:function ($$v) {_vm.$set(_vm.formData, "section", $$v)},expression:"formData.section"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3589094990)})],1):_vm._e()],1),_c('b-col',{staticClass:"mb-1",attrs:{"md":"6","xl":"6"}},[(_vm.typeUser == 'student' || _vm.typeUser == 'teacher')?_c('b-form-group',{attrs:{"label":"Aula *"}},[_c('validation-provider',{attrs:{"name":"Aula","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{model:{value:(_vm.formData.classroom),callback:function ($$v) {_vm.$set(_vm.formData, "classroom", $$v)},expression:"formData.classroom"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3258353110)})],1):_vm._e(),_c('b-form-group',{attrs:{"label":"Telefono"}},[_c('vue-tel-input',{attrs:{"defaultCountry":'VE'},on:{"validate":_vm.validateNumber},model:{value:(_vm.formData.phone),callback:function ($$v) {_vm.$set(_vm.formData, "phone", $$v)},expression:"formData.phone"}})],1),_c('b-form-group',{attrs:{"label":"Fecha de Nacimiento"}},[_c('validation-provider',{attrs:{"name":"fecha de nacimiento","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"date"},model:{value:(_vm.formData.birthday),callback:function ($$v) {_vm.$set(_vm.formData, "birthday", $$v)},expression:"formData.birthday"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),(_vm.typeUser == 'student')?_c('b-form-group',{attrs:{"label":"Representante"}},[_c('validation-provider',{attrs:{"name":"representante","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.representativeOptions,"label":"name","reduce":function (item) { return item.id; }},model:{value:(_vm.formData.representative_id),callback:function ($$v) {_vm.$set(_vm.formData, "representative_id", $$v)},expression:"formData.representative_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,409523259)})],1):_vm._e(),_c('b-form-group',{attrs:{"label":"Colegio"}},[_c('validation-provider',{attrs:{"name":"colegio","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.schoolOptions,"label":"name","reduce":function (item) { return item.id; }},model:{value:(_vm.formData.school_id),callback:function ($$v) {_vm.$set(_vm.formData, "school_id", $$v)},expression:"formData.school_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Billetera"}},[_c('validation-provider',{attrs:{"name":"billetera","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":[
                  { id: 1, name: 'Prepago' },
                  { id: 2, name: 'Pospago' } ],"label":"name","reduce":function (item) { return item.name; }},model:{value:(_vm.formData.wallet.type),callback:function ($$v) {_vm.$set(_vm.formData.wallet, "type", $$v)},expression:"formData.wallet.type"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',[_c('ul',[(_vm.formData.wallet.type == 'Pospago')?_c('span',[(_vm.formData.wallet.renewed_days > 0)?_c('span',[_c('li',[_vm._v(" Días de préstamo: "+_vm._s(_vm.formData.wallet.renewed_days)+" días ")]),_c('li',[_vm._v(" Fecha de vencimiento: "+_vm._s(_vm.formatarFecha(_vm.formData.wallet.expiry_date))+" ")])]):_vm._e(),(_vm.formData.wallet.max_limit > 0)?_c('span',[_c('li',[_vm._v(" Límite de crédito: $ "+_vm._s(_vm.formData.wallet.max_limit)+" ")])]):_vm._e()]):_vm._e()])]),(_vm.formData.wallet.type == 'Pospago')?_c('b-form-group',{attrs:{"label":"Opciones"}},[_c('b-button',{staticClass:"mb-1 mb-sm-0 mr-0 mr-sm-1",attrs:{"variant":_vm.selectedOption === 'days' ? 'success' : 'primary',"block":_vm.$store.getters['app/currentBreakPoint'] === 'xs'},on:{"click":function($event){return _vm.selectOption('days')}}},[_vm._v(" Agregar días de préstamo ")]),_c('b-button',{staticClass:"mb-1 mb-sm-0 mr-0 mr-sm-1",attrs:{"variant":_vm.selectedOption === 'limit' ? 'success' : 'primary',"block":_vm.$store.getters['app/currentBreakPoint'] === 'xs'},on:{"click":function($event){return _vm.selectOption('limit')}}},[_vm._v(" Agregar límite de crédito ")]),(_vm.selectedOption === 'days')?_c('div',[_c('validation-provider',{attrs:{"name":"billetera","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('br'),_c('v-select',{attrs:{"placeholder":"Días de préstamo","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.optionsDays,"label":"name","reduce":function (item) { return item.id; }},model:{value:(_vm.formDataV1.days),callback:function ($$v) {_vm.$set(_vm.formDataV1, "days", $$v)},expression:"formDataV1.days"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,4283778479)})],1):_vm._e(),(_vm.selectedOption === 'limit')?_c('b-form-group',[_c('br'),_c('b-form-input',{attrs:{"placeholder":"Límite de crédito","type":"number"},model:{value:(_vm.formDataV1.max_limit),callback:function ($$v) {_vm.$set(_vm.formDataV1, "max_limit", $$v)},expression:"formDataV1.max_limit"}})],1):_vm._e()],1):_vm._e()],1),_c('b-row',[_c('b-col',{staticClass:"mt-2 d-flex justify-content-end"},[_c('b-button',{staticClass:"mb-1 mb-sm-0 mr-0 mr-sm-1",attrs:{"type":"submit","variant":"primary","block":_vm.$store.getters['app/currentBreakPoint'] === 'xs'}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":_vm.modeEdit ? 'EditIcon' : 'SaveIcon',"size":"16"}}),_vm._v(" "+_vm._s(_vm.modeEdit ? "Actualizar" : "Guardar")+" ")],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }