<template>
  <b-card>
    <validation-observer ref="productForm">
      <b-form @submit.prevent="SaveUpdateChanges">
        <b-row>
          <b-col md="6" xl="6" class="mb-1">
            <b-form-group label="Cedula *">
              <validation-provider
                #default="{ errors }"
                name="cedula"
                rules="required"
              >
                <b-form-input type="number" v-model="formData.id_card" @keypress="filterNumber" maxlength="15"/>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Nombre *">
              <validation-provider
                #default="{ errors }"
                name="nombre"
                rules="required"
              >
                <b-form-input v-model="formData.name" @keypress="filterString" maxlength="15"/>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Apellido *">
              <validation-provider
                #default="{ errors }"
                name="apellido"
                rules="required"
              >
                <b-form-input v-model="formData.last_name" @keypress="filterString" maxlength="15"/>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Correo *">
              <validation-provider
                #default="{ errors }"
                name="correo"
                rules="required"
              >
                <b-form-input v-model="formData.email" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Contraseña *">
              <validation-provider
                #default="{ errors }"
                name="contraseña"
                rules="required"
              >
                <b-form-input type="password" v-model="formData.password" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group
              label="Grado *"
              v-if="typeUser == 'student' || typeUser == 'teacher'"
            >
              <validation-provider
                #default="{ errors }"
                name="grado"
                rules="required"
              >
                <b-form-input v-model="formData.degree" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group
              label="Sección *"
              v-if="typeUser == 'student' || typeUser == 'teacher'"
            >
              <validation-provider
                #default="{ errors }"
                name="sección"
                rules="required"
              >
                <b-form-input v-model="formData.section" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="6" xl="6" class="mb-1">
            <b-form-group
              label="Aula *"
              v-if="typeUser == 'student' || typeUser == 'teacher'"
            >
              <validation-provider
                #default="{ errors }"
                name="Aula"
                rules="required"
              >
                <b-form-input v-model="formData.classroom" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Telefono">
              <vue-tel-input v-model="formData.phone" @validate="validateNumber" :defaultCountry="'VE'" />
            </b-form-group>

            <b-form-group label="Fecha de Nacimiento">
              <validation-provider
                #default="{ errors }"
                name="fecha de nacimiento"
                rules="required"
              >
                <b-form-input type="date" v-model="formData.birthday" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Representante" v-if="typeUser == 'student'">
              <validation-provider
                #default="{ errors }"
                name="representante"
                rules="required"
              >
                <v-select
                  v-model="formData.representative_id"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="representativeOptions"
                  label="name"
                  :reduce="(item) => item.id"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Colegio">
              <validation-provider
                #default="{ errors }"
                name="colegio"
                rules="required"
              >
                <v-select
                  v-model="formData.school_id"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="schoolOptions"
                  label="name"
                  :reduce="(item) => item.id"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Billetera">
              <validation-provider
                #default="{ errors }"
                name="billetera"
                rules="required"
              >
                <v-select
                  v-model="formData.wallet.type"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="[
                    { id: 1, name: 'Prepago' },
                    { id: 2, name: 'Pospago' },
                  ]"
                  label="name"
                  :reduce="(item) => item.name"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <div>
              <ul>
                <span v-if="formData.wallet.type == 'Pospago'">
                  <span v-if="formData.wallet.renewed_days > 0">
                    <li>
                      Días de préstamo: {{ formData.wallet.renewed_days }} días
                    </li>
                    <li>
                      Fecha de vencimiento:
                      {{ formatarFecha(formData.wallet.expiry_date) }}
                    </li>
                  </span>
                  <span v-if="formData.wallet.max_limit > 0">
                    <li>
                      Límite de crédito: $ {{ formData.wallet.max_limit }}
                    </li></span
                  >
                </span>
              </ul>
            </div>

            <b-form-group
              label="Opciones"
              v-if="formData.wallet.type == 'Pospago'"
            >
              <b-button
                :variant="selectedOption === 'days' ? 'success' : 'primary'"
                class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                @click="selectOption('days')"
              >
                Agregar días de préstamo
              </b-button>
              <b-button
                :variant="selectedOption === 'limit' ? 'success' : 'primary'"
                class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                @click="selectOption('limit')"
              >
                Agregar límite de crédito
              </b-button>

              <div v-if="selectedOption === 'days'">
                <validation-provider
                  #default="{ errors }"
                  name="billetera"
                  rules="required"
                >
                  <br />
                  <v-select
                    placeholder="Días de préstamo"
                    v-model="formDataV1.days"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="optionsDays"
                    label="name"
                    :reduce="(item) => item.id"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </div>

              <b-form-group v-if="selectedOption === 'limit'">
                <br />
                <b-form-input
                  placeholder="Límite de crédito"
                  type="number"
                  v-model="formDataV1.max_limit"
                />
              </b-form-group>
            </b-form-group>
          </b-col>
          <b-row>
            <b-col class="mt-2 d-flex justify-content-end">
              <b-button
                type="submit"
                variant="primary"
                class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              >
                <!-- icon save -->
                <feather-icon
                  :icon="modeEdit ? 'EditIcon' : 'SaveIcon'"
                  size="16"
                  class="mr-50"
                />
                {{ modeEdit ? "Actualizar" : "Guardar" }}
              </b-button>
            </b-col>
          </b-row>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BButton,
  BInputGroup,
  BInputGroupPrepend,
  BMedia,
  BAvatar,
  BFormSelect,
} from "bootstrap-vue";
import router from "@/router";
import store from "@/store";
import vSelect from "vue-select";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import { localize } from "vee-validate";
import es from "vee-validate/dist/locale/es.json";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

localize("es", es);

export default {
  components: {
    BMedia,
    BAvatar,
    BCard,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    BInputGroup,
    BInputGroupPrepend,
    vSelect,
    BFormSelect,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      selectedOption: null,
      isValidateNumber: false,
      optionsDays: [
        { id: 1, name: "1 día" },
        { id: 2, name: "2 días" },
        { id: 3, name: "3 días" },
        { id: 4, name: "4 días" },
        { id: 5, name: "5 días" },
        { id: 6, name: "6 días" },
        { id: 7, name: "7 días" },
        { id: 8, name: "8 días" },
        { id: 9, name: "9 días" },
        { id: 10, name: "10 días" },
        { id: 11, name: "11 días" },
        { id: 12, name: "12 días" },
        { id: 13, name: "13 días" },
        { id: 14, name: "14 días" },
        { id: 15, name: "15 días" },
        { id: 16, name: "16 días" },
        { id: 17, name: "17 días" },
        { id: 18, name: "18 días" },
        { id: 19, name: "19 días" },
        { id: 20, name: "20 días" },
        { id: 21, name: "21 días" },
        { id: 22, name: "22 días" },
        { id: 23, name: "23 días" },
        { id: 24, name: "24 días" },
        { id: 25, name: "25 días" },
        { id: 26, name: "26 días" },
        { id: 27, name: "27 días" },
        { id: 28, name: "28 días" },
        { id: 29, name: "29 días" },
        { id: 30, name: "30 días" },
        { id: 31, name: "31 días" },
      ],
      required,
      formData: {
        id_card: null,
        name: null,
        last_name: null,
        email: null,
        password: null,
        degree: null,
        section: null,
        classroom: null,
        phone: null,
        birthday: null,
        representative_id: null,
        school_id: null,
        wallet: {
          type: null,
          max_limit: null,
        },
        observation: null,
        max_limit: null,
      },
      activeDaysOrLimit: false,
      representativeOptions: [],
      schoolOptions: [],
      formDataV1: {
        days: null,
        max_limit: null,
      },
    };
  },
  computed: {
    modeEdit() {
      if (router.currentRoute.name === "user-edit") {
        return router.currentRoute.params.id;
      }
    },
    typeUser() {
      return router.currentRoute.params.typeUser;
    },
  },
  methods: {
    filterString(event) {
      const keyCode = event.keyCode ? event.keyCode : event.which;
      if (
        (keyCode >= 48 && keyCode <= 57) ||  
        keyCode === 33 || keyCode === 64 || keyCode === 35 ||  
        keyCode === 36 || keyCode === 37 || keyCode === 94 ||
        keyCode === 38 || keyCode === 42
      ) {
        event.preventDefault(); 
      }
    },
    filterNumber(event) {
      const keyCode = event.keyCode ? event.keyCode : event.which;
      if 
        (keyCode < 48 || keyCode > 57)
     {
        event.preventDefault(); 
      }
    },
    validateNumber(value) {
      this.isValidateNumber = value.valid
    },
    getVariant(selectedOption) {
      console.log(selectedOption);
      if (selectedOption == null) {
        return "primary";
      } else if (selectedOption === "days") {
        return "success";
      } else if (selectedOption === "limit") {
        return "secondary";
      }
    },
    formatarFecha(fechaOriginal) {
      if (!fechaOriginal) return ""; // Retorna un string vacío si la fechaOriginal no está definida
      const fecha = new Date(fechaOriginal);
      const meses = [
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Noviembre",
        "Diciembre",
      ];
      const dia = fecha.getDate();
      const mes = meses[fecha.getMonth()];
      const ano = fecha.getFullYear();
      return `${dia} ${mes} ${ano}`;
    },
    selectOption(option) {
      this.selectedOption = option;
    },
    async SaveUpdateChanges() {

      if (!this.isValidateNumber) {
        this.message(
          "AlertCircleIcon",
          "danger",
          "El número de teléfono no es válido"
        );
        return;
      }
      if (this.modeEdit) {
        this.update();
      } else {
        this.create();
      }
    },
    async create() {
      if (!(await this.$refs.productForm.validate())) {
        return;
      }

      this.formData.typeUser = this.typeUser;
      this.formData.formDataV1 = this.formDataV1;

      await store
        .dispatch("user/storeUser", this.formData)
        .then(() => {
          this.message(
            "checkCircleIcon",
            "success",
            "Se registro el Usuario exitosamente"
          );
          this.$router.push({ name: "users-" + this.typeUser + "s" });
        })
        .catch((error) => {
          this.message(
            "AlertCircleIcon",
            "danger",
            error.response.data.message
          );
        });
    },
    async update() {
      this.formData.typeUser = this.typeUser;
      this.formData.formDataV1 = this.formDataV1;

      await store
        .dispatch("user/updateUser", { id: this.modeEdit, data: this.formData })
        .then((res) => {
          this.message(
            "checkCircleIcon",
            "success",
            "Se actualizo el Usuario exitosamente"
          );
          this.$router.push({ name: "users-" + res.user.typeUser + "s" });
        })
        .catch((error) => {
          this.message(
            "AlertCircleIcon",
            "danger",
            error.response.data.message
          );
        });
    },
    async fetchData() {
      try {
        this.representativeOptions = await this.$store.dispatch(
          "user/fetchRepresentative",
          { representatives: true }
        );
        this.schoolOptions = await this.$store.dispatch("schools/index", {
          schools: true,
        });
      } catch (error) {
        console.log(error);
      }
    },
    async getData() {
      if (this.modeEdit) {
        this.formData = await store.dispatch("user/showUser", this.modeEdit);
      }
    },
    async deleteDays() {
      await store
        .dispatch("user/updateUser", {
          id: this.modeEdit,
          data: {
            wallet: {
              renewed_days: 0,
            },
            deleteDay: true,
          },
        })
        .then((res) => {
          this.message(
            "checkCircleIcon",
            "success",
            "Se actualizo el Usuario exitosamente"
          );
          this.$router.push({ name: "users-" + res.user.typeUser + "s" });
        })
        .catch((error) => {
          this.message(
            "AlertCircleIcon",
            "danger",
            error.response.data.message
          );
        });
    },
    message(icon, variant, text) {
      this.$toast({
        component: ToastificationContent,
        position: "top-right",
        props: {
          title: "Respuesta",
          icon: icon,
          variant: variant,
          text: text,
        },
      });
    },
  },
  mounted() {
    this.fetchData();
    this.getData();
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
